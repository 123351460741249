img.logo {
    margin-bottom: 10px;
    width: 48%;
}

img.unselected:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 10px;
    width:48%;
}

img.selected {
    border: solid 2px lime;
    width:48%;
    margin-bottom: 10px;
    /* margin: -2px; */
}