
.Frames {
    padding-bottom: 50px;
  }
  
  .Frames h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      overflow: hidden;
      line-height: 1.5;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
  .Frames h1  {
    font-family: "Open Sans", sans-serif;
    font-weight: 450;
    font-size: 36px;
    line-height: 1.2;
    padding-top: 5px;
  }

  .react-datepicker {
    font-size: 1.3rem !important;
  }
  
  .react-datepicker__current-month {
    font-size: 1.5rem !important;
  }
  
  .react-datepicker__header {
    padding-top: 6px !important;
  }
  
  .react-datepicker__navigation {
    top: 13px !important;
  }
  
  .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
  }