.notification-text {
    text-align:center;
    font-size:20px;
    margin-left:-32px;
    cursor: pointer;
    /* padding-left:20px; */
    /* margin-left:-25px; */
}

.notification-text-mobile {
    text-align: center;
    font-size: 12px;
    margin-left:-20px;
    line-height: 14px;
    /* padding-left:20px; */
}

.notification-text-title {
  text-align: center;
  font-size: 36px;
  padding-right: 24px;
  cursor: pointer;
}

.img-img {
  width: auto;
  height: auto;
}

.notification-text-mobile-title {
  text-align: center;
  font-size: 16px;
  padding-right:20px;
  padding-top:5px;
  line-height: 18px;
}

.notification-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -30px;
  cursor: pointer;
}

.notification-img-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}

.notification-tiny {
  text-align: center;
  font-size: 12px;
  margin-left: -30px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: -2px;
  right: 8px;
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 34px;
  padding: 0px;
  line-height: 1;
  color: #eee;
}