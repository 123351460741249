.modal-dialog {
    width: 80vw;
    max-width: 1140px;
    padding: 12px;
    /* padding-right: 12px; */
}

.modal-dialog-phone {
    width: 90vw;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.fade.in.modal {
    display:flex !important;
}

input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
}