.App {
  margin-top: 15px;
  margin-bottom: 50px;
}

.App .navbar-brand {
  font-weight: bold;
  padding: 3px;
  padding-top: 1px;
  margin-top: -1px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.error-message {
  background-color: #ff7272;
  color: white;
  padding: 1em;
  font-weight: bold;
}

.success-message {
  background-color: #008D8D;
  color: white;
  padding: 1em;
  font-weight: bold;
}

.nudge-message {
  background-color: #337AB7;
  color: white;
  padding: 1em;
  font-weight: bold;
}