.line {
  cursor: crosshair;
  position: absolute;
  z-index: 2;
}

#verticalLine {
  border-left: 2px dotted rgba(255, 255, 255, 0.6);
}

#horizontalLine {
  border-bottom: 2px dotted rgba(255, 255, 255, 0.6);
}

#LabelViewContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  
  #Middle {
    position: relative;
    width: 1000px;
    height: auto;
    margin: 0 auto;
  
  }
  
  #LabelView {
    float: left;
    position: relative;
    cursor: crosshair;
    top: 15%;
    /*left: 25%;*/
    width: 720px;
    height: auto;
    margin: 0 auto;
  }
  
  .BoundingBox {
    cursor: crosshair;
    position: absolute;
    /* border: 2px solid rgba(255, 51, 0, 0.8); */
    /* border: 2px solid Red; */
    z-index: 4;
  }
  
  .DeleteBoxButton {
    cursor: default;
    text-align: center;
    line-height: 22px;
    font-size: 16px;
    position: absolute;
    color: white;
    top: -14px;
    right: -14px;
    height: 28px;
    width: 28px;
    background-color: grey;
    border-radius: 18px;
    border: 4px solid white;
    z-index: 6;
  }

  .BoxLabel {
      color: white;
      display: inline-block;
      padding-right:5px;
  }
  
  .unselectable {
    user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  
  #BoundingBoxes {
    position: absolute;
    cursor: crosshair;
    width: 100%;
    height: 100%;
    /*border: 1px solid rgba(0, 100, 200, 0.64);
    #z-index: 9999;*/
  }
  
  #LabelViewImg {
    display: block;
    cursor: crosshair;
    position: relative;
    width: 100%;
    height: auto;
  }

  #Instructions {
      padding-top: 15px;
  }
  
  /* .Instruction {
    background-color: black;
    color: white;
    height: 100px;
  }
   */
  /* #Header p {
    padding-top: 15px;
    margin: 0px;
    font-size: 20px;
    line-height: 20px;
  }
  
  #Header {
    position: relative;
    text-align: center;
    margin: 0 auto;
    color: #232426;
  } */
  
  #Crosshair {
    position: absolute;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 128, 0, 0.5);*/
    z-index: 3;
  }
  
  #SidePanel {
    float: right;
    position: relative;
    padding: 10px;
    width: 270px;
    height: 100%;
  }
  
  #InfoPanel table {
    margin-left: 10px;
  }

  #InfoPanel tbody {
      height: 100%
  }
  
  #InfoPanel td {
    padding: 10px;
  }

  #InfraredButtonPanel {
      text-align: center;
      font-weight: bold;
      line-height: 14px;
      font-size: 20px;
      width: 250px;
  }

  #InfraredButtonPanel table {
      width: 100%;
  }
  
  #InfraredButtonPanel tbody {
    width: 100%;
    height: 100%;
  }

  .KeyboardKey {
    border: 2px solid #d1d4d6;
    background-color: #dee0e2;
    color: white;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 40px;
  }
  
  #Submit {
    position: relative;
    /*top: 20px;*/
  }
  
  #Submit form {
    text-align: center;
    /*width: 100%;*/
  }
  
  #submitButtonOLD {
    cursor: pointer;
    position: relative;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    /*padding: 15px 32px;*/
    height: 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 250px;
    border-radius: 5px;
  }

  #submitButton {
      cursor: pointer;
      margin: 5px;
      width: 250px;
  }

  #clearButton {
      cursor: pointer;
      margin: 5px;
      width: 250px;
  }
  
  #deleteButton {
      cursor: pointer;
      margin: 5px;
      width: 250px;
  }

  #labelButtonPanel {
      margin: 5px;
      width:250px;
  }
  
  #submitButton:hover {
    background-color: #3a823d;
  }