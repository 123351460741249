h2 {
    font-family: "Open sans", sans-serif !important;
    font-weight: 600;
    font-size: 28px;
}

h3 {
    font-family: "Open sans", sans-serif !important;
    font-weight: 600;
    font-size: 18px;
}

div.center {
    text-align: center;
    width: 100%;
    padding-right:30px;
}

li.helpList {
    margin-bottom: 20px;
}