@media all and (min-width: 480px) {
    .SignupNewCap {
      padding: 60px 0;
    }
  
    .SignupNewCap form {
      margin: 0 auto;
      max-width: 500px;
      min-width: 400px;
    }
  }
  
  .SignupNewCap form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }