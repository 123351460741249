@media all and (min-width: 480px) {
    .Account {
      padding: 60px 0;
    }
    
    .Account .builder h1 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }

    .password {
        margin: 0 auto;
        max-width: 320px;
    }

    .info {
        padding: 5px;
    }
  
    /* .Account form { */
      /* margin: 0 auto; */
      /* max-width: 320px; */
    /* } */
  }

  .wrapper {
    overflow: auto;
    height: 70vh;
    width: 70vw;
  }
  
  /* table {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  table th,
  table td {
    width: 50px;
    padding: 5px;
    background-color: white;
  }
  
  table tbody {
    height: 60vh;
  }
  
  table th {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  table th:nth-child(1) {
    left: 0;
    z-index: 3;
  }
  
  table td {
    text-align: center;
    white-space: pre;
  } */
  
  /* table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 1;
  } */
  
  table tr:hover {
    cursor: pointer;
  }

  .dropdown-toggle,
  .dropdown-menu {
    width: 100%;
  }