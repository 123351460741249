img.logo {
    margin-bottom: 10px;
    width: 48%;
}

img.unselected {
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    overflow: auto;
}

img.selected-image {
    position: relative;
    z-index: 0;
    width: 100%;
}

img.unselected:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 10px;
    width:96%;
    z-index: 0;
    position: inherit;
}

img.selected {
    border: solid 2px lime;
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    /* margin: -2px; */
}

img.selected-mobile {
    border: solid 2px lime;
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
}

img.unselected-mobile {
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    overflow: auto;
}

img.unselected-mobile:hover {
    /* border: solid 2px red; */
    margin: -2px;
    margin-bottom: 10px;
    width:96%;
    z-index: 0;
    position: inherit;
}

div.unselected {
    width:48%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.unselected:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 10px;
    width:48%;
    z-index: 0;
    position: inherit;
}

div.selected {
    border: solid 2px lime;
    width:48%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.unselected-mobile {
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.unselected-mobile:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 10px;
    width:96%;
    z-index: 0;
    position: inherit;
}

div.selected-mobile {
    border: solid 2px lime;
    width:96%;
    margin-bottom: 10px;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-dialog {
    width: 80vw;
}

.modal-dialog-phone {
    width: 90vw;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.fade.in.modal {
    display:flex !important;
}