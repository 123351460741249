.Cameras {
  padding-bottom: 50px;
}

.Cameras h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.modal-dialog {
  width: 80vw;
}
.modal-dialog-phone {
  width: 98vw;
}

.react-transform-component {
  width: unset !important;
  height: unset !important;
  /* aspect-ratio: 1.7684; */
}

.react-transform-element {
  width: unset !important;
  height: unset !important;
  /* aspect-ratio: 1.7684; */
}

img {
  /* max-width:  100%;
  max-height: 100%; */
  display: block;
}

/* .TransformComponent .container {
  width: 100%;
}

.TransformWrapper .content {
  width: 100%;
} */