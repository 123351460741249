@media all and (min-width: 480px) {
    .Activate {
        padding: 60px 0;
    }

    .Activate form {
        margin: 0 auto;
        max-width: 320px;
    }

    .instructions {
        margin: 0 auto;
        max-width: 640px;
        padding-bottom: 30px;
        text-align: center;
        line-height: 2.5;
    }
}