.modal-dialog {
    width: 80vw;
    max-width: 1140px;
    /* padding: 12px; */
    padding-right: 24px;
    margin: auto;
    margin-top: 30px;
    /* margin-top: 30px; */
}

.modal-dialog-phone {
    width: 90vw;
    margin: auto;
    margin-top: 10px;
    /* margin-bottom: 10px; */
    padding-right: 10px;
}

div.fade.in.modal {
    display:flex !important;
}

input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
}

img.unselected {
    width:96%;
    /* margin-bottom: 10px; */
    z-index: 0;
    position: relative;
    overflow: auto;
}

img.selected-image {
    position: relative;
    z-index: 0;
    width: 100%;
}

img.unselected:hover {
    border: solid 2px red;
    margin: -2px;
    /* margin-bottom: 10px; */
    width:96%;
    z-index: 0;
    position: inherit;
}

img.selected {
    border: solid 2px lime;
    width:96%;
    /* margin-bottom: 10px; */
    z-index: 0;
    position: relative;
    /* margin: -2px; */
}

img.selected-mobile {
    border: solid 2px lime;
    width:96%;
    /* margin-bottom: 10px; */
    z-index: 0;
    position: relative;
}

img.unselected-mobile {
    width:96%;
    /* margin-bottom: 10px; */
    z-index: 0;
    position: relative;
    overflow: auto;
}

img.unselected-mobile:hover {
    /* border: solid 2px red; */
    margin: -2px;
    /* margin-bottom: 10px; */
    width:96%;
    z-index: 0;
    position: inherit;
}

div.unselected {
    width:48%;
    margin-bottom: 0px;
    z-index: 0;
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

div.unselected:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 0px;
    width:48%;
    z-index: 0;
    position: inherit;
    /* padding: 5px; */
}

div.selected {
    border: solid 2px lime;
    width:48%;
    margin-bottom: 0px;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

div.unselected-mobile {
    width:96%;
    margin-bottom: 0px;
    z-index: 0;
    position: relative;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

div.unselected-mobile:hover {
    border: solid 2px red;
    margin: -2px;
    margin-bottom: 0px;
    width:96%;
    z-index: 0;
    position: inherit;
    /* padding: 5px; */
}

div.selected-mobile {
    border: solid 2px lime;
    width:96%;
    margin-bottom: 0px;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}