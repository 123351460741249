
.Events {
  padding-bottom: 50px;
}

a {
  text-decoration: none !important;
}

.panel-default > .panel-heading {
  background-color: #FFF;
}

.panel-default > .panel-heading:hover {
  background-color: #F5F5F5;
}

.Events h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.Events h1  {
  font-family: "Open Sans", sans-serif;
  font-weight: 450;
  font-size: 36px;
  line-height: 1.2;
  padding-top: 5px;
}

.Replay {
  padding-bottom: 50px;
}

.modal-container {
  position: relative;
}

.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}