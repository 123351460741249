
.FrameHistory {
    padding-bottom: 50px;
  }
  
  .FrameHistory h4 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      overflow: hidden;
      line-height: 1.5;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
  .FrameHistory h1  {
    font-family: "Open Sans", sans-serif;
    font-weight: 450;
    font-size: 36px;
    line-height: 1.2;
    padding-top: 5px;
  }