@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .Login .password h1 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      text-align: center;
      font-size: 28px;
    }
  }