h1 {
    font-family: "Open sans", sans-serif !important;
    font-weight: 600;
    font-size: 36px;
    text-align: center;
}

input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 10px;
}